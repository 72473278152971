import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-database-testing',
  templateUrl: './database-testing.component.html',
  styleUrls: ['./database-testing.component.scss']
})
export class DatabaseTestingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
