import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'prodesign';
    
metaData = {
    "home": {
        "title": "IT Services & Solutions | App Design & Development – Prodesign",
        "desc": "Prodesign is the Best App design & development, App Support, IT Services & Solutions Company in Hyderabad, India. We are best IT Services & Solutions providers.",
        "keywords": "Prodesign Technologies, IT Services & Solutions, managed services, app design & development, software, software apps, computer services, services in Hyderabad, application development, application design, core values, big data, data science, cloud technologies, e-commerce, mobile apps, 3D animation, what we do, procedure, design, development, testing, requirement, analysis, deployment, contact us."
    },
    "aboutus/why-choose-us": {
        "title": "Prodesign -About us | About Prodesign Technologies in Hyderabad",
        "desc": "Prodesign utilizes the web to shape your business and guarantee to gov acrid clients. We additionally work to improve straightforwardness with our customers.",
        "keywords": "Prodesign Technologies, IT Services & Solutions, managed services, app design & development, software, software apps, computer services, services in Hyderabad, application development, application design, core values, big data, data science, cloud technologies, e-commerce, mobile apps, 3D animation, what we do, procedure, design, development, testing, requirement, analysis, deployment, contact us."
    },
    "services/quality-assurance-testing/erp-testing": {
        "title": "ERP Testing - Prodesign | Testing Services in Hyderabad",
        "desc": "Prodesign utilizes the quality assurance (QA) process to ensure the ERP system which helps to operate correctly before the full launch.",
        "keywords": "Testing, Services, Prodesign services, ERP system, ERP, ERP software, software testing, QA testing, quality assurance, testing tools."
    },
    "services/quality-assurance-testing/automation-testing": {
        "title": "Automation Testing in Hyderabad - Software Testing | Prodesign ",
        "desc": "Prodesign provides world-class Automation Testing Services in Hyderabad which help organizations to expand their use of automation to reduce time to market.",
        "keywords": "Prodesign Technologies, Testing, Automation Testing, Services, software testing, testing automation selenium, Testing services, testing tools, Automation Anywhere, automation testing tools."
    },
    "services/quality-assurance-testing/salesforce-testing": {
        "title": "Salesforce Testing Services | Prodesign Technologies Hyderabad",
        "desc": "Prodesign Salesforce testing professionals work on testing assignments for projects that follow traditional as well as agile software lifecycles.",
        "keywords": "Testing, Testing services, software testing, salesforce testing, Prodesign services, salesforce, Salesforce services, testing tools."
    },
    "services/quality-assurance-testing/mobile-testing": {
        "title": "Mobile Testing - Software Testing | Prodesign Technologies",
        "desc": "Prodesign has expertise in delivering the best Mobile Testing Services at affordable prices for both functional and non-functional testing of Mobile Apps.",
        "keywords": "Prodesign testing, Mobile application Testing, software testing, mobile testing, app testing, application testing, Services, testing tools, Testing company."
    },
    "services/quality-assurance-testing/etl-testing": {
        "title": "ETL Testing Services – Prodesign | Software Testing Services",
        "desc": "Prodesign ETL testing services include a source to target and data completeness validations. Our ETL tools provide data integration solution for Big Data.",
        "keywords": "Testing, testing services, testing tools, ETL Testing, Software Testing Services, Services, Testing company, ETL tools."
    },
    "services/quality-assurance-testing/test-advisory-services": {
        "title": "Test Advisory Services | Prodesigntek - Software Testing",
        "desc": "Prodesign Test Advisory Services targeting at organizations looking to enhance their testing practices to affluently meet the business requirements.",
        "keywords": "Testing services, testing tools, testing, software testing tools, advisory services, services, Software Testing Companies."
    },
    "services/quality-assurance-testing/database-testing": {
        "title": "Database Testing - Software Testing Tool | Prodesigntek",
        "desc": "Prodesign Technologies offers the finest Database Testing Services in Hyderabad that make sure to get the most effective result of a tested product.",
        "keywords": "Testing services, testing tools, testing, software testing tools, software testing companies, database testing, database."
    },
    "services/quality-assurance-testing/web-api-testing": {
        "title": "Web Application Penetration Testing Services | Prodesign",
        "desc": "Prodesign offers Web Application Penetration Testing Services to the clients to analyze security ambiguities at different levels and report the same.",
        "keywords": "Software testing tools, testing, testing services, web test, api testing, services, web application testing, web application."
    },
    "services/business-intelligence-analytics/dashboard": {
        "title": "BI Dashboard Services | Business Intelligence | Prodesigntek",
        "desc": "At Prodesign, we provide a wide range of BI Dashboard Services to the clients including creation, deployment, development, maintenance and many more.",
        "keywords": "Business Intelligence analyst, BI analytics, business intelligence tools, business intelligence, BI Tools, dashboard. "
    },
    "services/business-intelligence-analytics/bi-support": {
        "title": "BI Support & Maintenance Services | Prodesign Technologies",
        "desc": "Prodesign provides relentless 24*7 BI Support & Maintenance services ranging from service desk inquiries to bug resolution fixes and critical reporting cycle.",
        "keywords": "Business Intelligence analyst, BI analytics, Business intelligence tools, Business intelligence, BI Tools, BI support, support 24/7."
    },
    "services/business-intelligence-analytics/microsoft-power-bi": {
        "title": "Microsoft Power Business Intelligence | Prodesign Technologies",
        "desc": "Prodesign Technologies provides the best Power Business Intelligence services helping to tackle a company’s information to make clever decisions faster.",
        "keywords": "Microsoft Power BI, Power BI, Power BI Services, Business Intelligence analyst, Business Intelligence, Business intelligence tools."
    },
    "services/business-intelligence-analytics/oracle-bi": {
        "title": "Oracle Business Intelligence Cloud Services | Prodesigntek ",
        "desc": "Prodesign provides Oracle Business Intelligence Cloud Services (BICS) which helps you minimize maintenance and operational expenses.",
        "keywords": "Prodesign Technologies, Oracle, Oracle support, Oracle cloud, cloud services, Oracle BI, oracle services cloud, Business intelligence tools, Business intelligence, Business intelligence analyst."
    },
    "services/business-intelligence-analytics/reporting-analytics": {
        "title": "Business Intelligence Reporting & Analytics Services – Prodesign",
        "desc": "Prodesign offers world-class Business Intelligence Reporting & Analytics Services in Hyderabad including building dynamic and ad hoc reporting.",
        "keywords": "Business Intelligence, Business Intelligence Analyst, Business intelligence tools, Business intelligence analytics, BI Reporting, BI analytics."
    },
    "services/animation/animation": {
        "title": "3D Animation Services in Hyderabad | Prodesign Technologies",
        "desc": "Prodesign Technologies team provides creative, attractive and top quality 3D animation services to the clients to enhance their product.",
        "keywords": "3D Animation, animation, animation software, animation maker, animation companies, 3D design, 3D design software."
    },
    "services/mobile-development/mobile-application-development": {
        "title": "Mobile Application Development | Prodesigntek | App Developers",
        "desc": "Prodesign App Developers has vast experience in offering various Mobile Applications Development like Android & Ios Applications and Windows Applications.",
        "keywords": "Mobile Application Development, Android, IOS, Windows Application, app development software, application development, application, app developers, android app development, android application."
    },
    "services/api-integration/api-integration": {
        "title": "Best API Integration Services | Prodesign Technologies | API",
        "desc": "Prodesign Technologies Team is highly specialized in providing API (Application Programming Interface) for the client to meet their Business needs.",
        "keywords": "API Integration, Integration Services, Application programming Interface, Integration Testing, integration services, API, services."
    },
    "services/other-services/e-commerce-app-development": {
        "title": "e-commerce Application Development | Prodesign Technologies",
        "desc": "Prodesign Technologies has experienced e-commerce Application Development Team in Creating various online Stores and Websites to the Clients.",
        "keywords": "Services, e-commerce, website design, e-commerce website, e-commerce application, e-commerce website development, e-commerce development, e-commerce business, application development, websites."
    },
    "services/other-services/application-development": {
        "title": "Web Application Development | Prodesign Technologies",
        "desc": "Prodesign is a Application Development company at Enterprise and Entrepreneurial levels & provides Application integration, maintenance and support to clients.",
        "keywords": "Websites, Application design, application integration, integration, development, application programming interface, application, services, web application development, web application, web development."
    },
    "services/other-services/ application-support": {
        "title": "Application Support Services | Prodesign Technologies",
        "desc": "Prodesign Technologies provides the best multiple application supports like AWS, DBA support, QA support to help businesses to grow.",
        "keywords": "Services, application, AWS, DBA, QA, support, Prodesign Technologies."
    },
    "services/other-services/ digital-marketing": {
        "title": "Digital Marketing | SEO, PPC, Social Media | Prodesigntek",
        "desc": "Prodesign has experience which allows it to provide a high level of services in the medium of Digital marketing. Services include SEO, SEM, SMO, and SMM.",
        "keywords": "SEO, SMM, SMO, Digital Marketing, Digital Marketing Services, PPC, Search engine optimization, social media marketing, services."
    },
    "services/other-services/ business-consulting": {
        "title": "Business Consulting Services | Prodesign Technologies",
        "desc": "Prodesigntek’s Business consulting services help's clients revamp their Business to develop the quality of their productivity in the market.",
        "keywords": "Business Consulting, Services, Business Development, consulting services, product, business services."
    },
    "services/other-services/ marketing-development": {
        "title": "Marketing Development Services | Prodesign Technologies",
        "desc": "Prodesign Technologies experienced team in providing business marketing services to the clients to achieve greater overall ROI.",
        "keywords": "Prodesign Technologies, Development, Services, business marketing, marketing services, business services, ROI."
    },
    "services/other-services/ custom-application": {
        "title": "Custom Software - Application Development | Prodesigntek",
        "desc": "Prodesign Technologies has experienced team, offers custom application development services includes web, mobile, cloud applications for our clients.",
        "keywords": "Software apps, software application, Development, mobile application development, mobile, web application, application, Services, software development."
    },
    "solutions/big-data/scala": {
        "title": "Scala Web Services in Hyderabad - Big Data Hadoop | Prodesign",
        "desc": "Prodesigntek specializes in providing world-class Scala Web services in Hyderabad. We provide the best Scala development, consultancy & support service.",
        "keywords": "Scala, web services, big data, Hadoop, Services, Development, big data Hadoop."
    },
    "solutions/big-data/spark": {
        "title": "Apache Spark Web Services in Hyderabad-Big Data | Prodesigntek",
        "desc": "Prodesigntek offers the best Apache Spark Web services in Hyderabad with quality and efficient solutions. Reach us to know more about Spark Development.",
        "keywords": "Spark, Big Data, Hadoop, Services, apache, apache spark, web services, Development, big data Hadoop."
    },
    "solutions/big-data/kafka": {
        "title": "Apache Kafka Web Services in Hyderabad-Big Data | Prodesigntek",
        "desc": "Prodesign provides world-class Apache Kafka web services in Hyderabad efficiently on-time and in the low-cost budget. Reach us today to get Kafka web service!",
        "keywords": "Kafka, Big Data, Hadoop, Services, apache, apache Kafka, web services, development, Big Data Hadoop. "
    },
    "solutions/big-data/oozie": {
        "title": "Oozie services -Big Data Hyderabad | Prodesign Technologies",
        "desc": "Prodesign using Oozie for workflow scheduler framework to oversee Apache Hadoop jobs. Oozie is coordinated with the rest of the Hadoop stack. ",
        "keywords": "Oozie, Big Data, Hadoop, Services, Web services, development, Big Data Hadoop, apache."
    },
    "solutions/big-data/mapreduce": {
        "title": "Prodesign Technologies | Mapreduce service-Big Data | Mapreduce",
        "desc": "Prodesign providing MapReduce for a software framework, programming model and a related usage for handling and generating substantial informational indexes. ",
        "keywords": "Mapreduce, Big Data, Hadoop, Services, web services, Big Data Hadoop, apache, development."
    },
    "solutions/data-science/ai-machine-learning": {
        "title": "Artificial Intelligence and Machine Learning | Prodesigntek",
        "desc": "Prodesign is one of the most reliable company in implementing Artificial Intelligence and Machine learning Technologies to the clients.",
        "keywords": "Artificial Intelligence, Machine Learning, Technologies, artificial, Machine, data science, ML, AI, services, software, software apps, learning."
    },
    "solutions/data-science/predictive-analytics": {
        "title": "Predictive Analytics | Predictive-Data Science | Prodesign",
        "desc": "Prodesign prefers Predictive analytics for the act of removing data from existing informational collections so as to decide designs and foresee future results.",
        "keywords": "predictive analytics, data science, design, data, analytics, services, Technologies, software, software apps."
    },
    "solutions/data-science/reinforcement-learning": {
        "title": "Reinforcement Learning-Hyderabad | Prodesign Technologies",
        "desc": "Prodesign Reinforcement allows machines and software agents to consequently decide the perfect conduct inside a specific setting, so as to expand its execution.",
        "keywords": "Prodesign Technologies, reinforcement learning, software, services, technologies, software apps, Learning, machines, reinforcement."
    },
    "solutions/data-science/deep-learning": {
        "title": "Deep Learning Service | Deep Learning | Prodesign Technologies",
        "desc": "Prodesign Expert in providing Deep Learning for a piece of a more extensive group of machine learning strategies dependent on learning information portrayals.",
        "keywords": "Reinforcement Learning, Reinforcement, services, software, software apps, learning, technologies, solutions."
    },
    "solutions/data-science/block-chain": {
        "title": "Blockchain Development | Blockchain Developers | Prodesigntek",
        "desc": "At Prodesign Technologies, we have a team of expert Blockchain developers whom all have tremendous knowledge of Blockchain technology.",
        "keywords": "Services, software apps, software, technologies, blockchain, blockchain technology, blockchain development, blockchain developer. "
    },
    "solutions/data-science/robotic-process-automation": {
        "title": "Robotic Process Automation | RPA Developers | Prodesigntek",
        "desc": "Prodesign is the leading Robotic Process Automation (RPA) Company in Hyderabad. Our services include Process Improvement, Automation Anywhere and Blue Prism.",
        "keywords": "RPA, Automation, RPA developers, developers, software, software apps, Technologies, automation anywhere, blue prism, services."
    },
    "solutions/data-science/internet-of-things": {
        "title": "Internet of Things – Real Time | Prodesign Technologies",
        "desc": "Our Prodesign Technologies, Real-time (IoT) developers help to control those devices are driving greater cost savings and more innovation.",
        "keywords": "Real-time, IoT, IoT Technology, technologies, developers, services, software, software apps, internet of things."
    },
    "solutions/python/python": {
        "title": "Python Technology in Hyderabad | Prodesign Technologies | Python",
        "desc": "Prodesign Expert on Python. Python is a multi-worldview, general-purpose, translated, abnormal state programming dialect.",
        "keywords": "Python, python developer, Services, Technologies, software, software apps, Solutions, developers."
    },
    "solutions/servicenow/servicenow": {
        "title": "Servicenow Technology in Hyderabad | Prodesign Technologies | servicenow",
        "desc": "At Prodesign we guide and support our clients on ITSM. We take care of your IT business with scalable, customized and cost-effective services.",
        "keywords": "Servicenow, servicenow developer, developer, technologies, services, software, software apps, solutions."
    },
    "solutions/cloud-technologies/salesforce": {
        "title": "Sales Force in Hyderabad | Sales Force Technology | Prodesigntek",
        "desc": "Prodesign offers Sales force. It is an online item that permits Marketing, Sales, and Customer Support workers to follow data about their prospects and clients.",
        "keywords": "Salesforce, customer support, salesforce developer, developers, software, Technologies, Services."
    },
    "solutions/cloud-technologies/aws": {
        "title": "AWS in Hyderabad | Amazon Web Services | Prodesign Technologies",
        "desc": "Prodesign providing AWS for a safe cloud administrations stage, offering figure control, database amassing and other users to organizations to scale and create.",
        "keywords": "Prodesign Technologies, AWS, Services, Amazon Web Service, Cloud Technologies, Cloudfront, Software Solutions, Technologies, software."
    },
    "solutions/cloud-technologies/azure": {
        "title": "Azure Technology | Azure in Hyderabad | Prodesign Technologies ",
        "desc": "Prodesign giving an opportunity for a stage as an administration answer for building and facilitating arrangements utilizing Microsoft's items and server farms.",
        "keywords": "Services, software, Technologies, azure, technology, Microsoft, cloud technologies, software solutions, Cloudfront."
    },
    "product/bamsa": {
        "title": "BAMSA Product of Prodesign Technologies | Prodesign Technologies",
        "desc": "BAMSA an imaginative response that begins from total effort to achieve an affiliations goal for streamline and manage all of the strategies inside a business.",
        "keywords": ""
    },
    "product/ams": {
        "title": "Prodesign Technologies | Prodesign Technologies Product AMS",
        "desc": "AMS is an application to give a secured Monitoring to a social event of Villas/Apartment Communities and perfect solution for your security issues.",
        "keywords": ""
    },
    "product/hms": {
        "title": "HMS Prodesign Technologies Product | Prodesign Technologies ",
        "desc": "HMS is a planned Hospital Information System, which keeps an eye on all the major helpful zones of multi-specialty specialist's offices.",
        "keywords": ""
    },
    "product/bfs": {
        "title": "BFS Product  of  Prodesign Technologies | Prodesign Technologies",
        "desc": "BFS Corporate Subscriptions can give modified access to your whole association to Prodesigntek Research and Tools.",
        "keywords": ""
    },
    "product/rems": {
        "title": "Prodesign Technologies | Prodesign Technologies Product REMS",
        "desc": "REMS is a Multi-Level Marketing runs with substantial work with many numbers of functionalities, a colossal number of representatives and clients.",
        "keywords": ""
    },
    "product/wholesaler-retailer": {
        "title": "Wholesaler & Retailer Product | Prodesign Technologies ",
        "desc": "A wholesaler is an individual, need to move the items in a package and it needs to purchase the items is retailer and Agent is a representative of a wholesaler.",
        "keywords": ""
    },
    "careers/domestic": {
        "title": "Prodesign Technologies | Jobs in Hyderabad | India",
        "desc": "",
        "keywords": "Jobs in Hyderabad, jobs in India, Software Jobs, Recruiters, IT Recruiters, programming Jobs."
    },
    "careers/global": {
        "title": "Prodesign Technologies | USA Jobs | Jobs",
        "desc": "We also work for our clients to recruit professional employees to client location in the USA.",
        "keywords": "Prodesigntek, IT Technologies, USA Jobs, Jobs in abroad, PI solutions, Services."
    },
    "contactus": {
        "title": "Contact us | IT Solutions | Prodesign Technologies",
        "desc": "Prodesign Technologies is an IT solutions and Services provider company all over the world.",
        "keywords": "IT Solutions and Services, Prodesigntek, Contact us, IT solutions in Hyderabad, IT services in India. "
    },
    "frequently-asked-questions": {
        "title": "Frequently Asked Questions | FAQ - Prodesign Technologies.",
        "desc": "Prodesign Technologies is committed to furnishing clients with quality items and trustworthy data that they can utilize and go down for ages.",
        "keywords": "Prodesign Technologies company, prodesigntek, FAQ, Latest FAQ website, best FAQ pages."
    },
    "sitemap/sitemap": {
        "title": "Sitemap - Prodesign Technologies | IT Services.",
        "desc": "IT services and software solutions",
        "keywords": "IT services, software solutions"
    },
    "privacy-policy": {
        "title": "Prodesign Technologies - Privacy & Policy for IT Services | software.",
        "desc": "Our privacy policy guarantees you that any details shared by you will using any and all means or conditions, not be abused, sold, or potentially rented.",
        "keywords": "IT Services, software solutions, python, digital marketing, service now, salesforce testing, reporting Analytics, company Privacy & Policy."
    },
    "aboutus/employee-login": {
        "title": "  ProHRMS of Prodesign | Prodesign Technologies",
        "desc": "ProHRMS is a complete solution for an Organization.ProHRMS is employee login credential and address for Prodesign Technologies.",
        "keywords": ""
    }
}
    constructor(private router: Router, private titleService: Title, private meta: Meta) {

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                console.log("start");
            }

            if (event instanceof NavigationEnd) {
                let croute=this.router.url.replace("/","");
               if(this.router.url=="/"){
                croute="home";
               }
               if(this.metaData[croute]){
                this.titleService.setTitle(this.metaData[croute].title);
                this.meta.addTag({ name: 'keywords', content: this.metaData[croute].keywords });
                    this.meta.addTag({ name: 'description', content: this.metaData[croute].desc });
               }
                
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });

    }
}
