import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $:any;


@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss']
})
export class GlobalComponent implements OnInit {
usjobs:any;
applyForm: FormGroup;
firstname='';
lastname='';
email='';
mobile='';
country='';
state='';
zip='';
city='';
jobid='';
docFile;
submitted=false;
 countries=[];
    states={};
    cities={};
    processData(data) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < data.Countries.length; i++) {
        this.countries.push(data.Countries[i].CountryName);
        this.states[data.Countries[i].CountryName] = data.Countries[i].States;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < data.Countries[i].States.length; j++) {
          this.cities[data.Countries[i].States[j].StateName] = data.Countries[i].States[j].Cities;
        }
      }  
    }
  constructor(private http:HttpClient,private formBuilder:FormBuilder) { }
  onSubmit(){
    this.submitted=true;
    if(this.applyForm.invalid){
      return;
    }
    let reqBody={
      jobid:this.jobid,
      fullname:this.firstname+""+this.lastname,
      email:this.email,
      mobile:this.mobile,
      country:this.country,
      state:this.state,
      city:this.city,
      zip:this.zip,
	  docFile:this.docFile,
      resume:this.docFile.filename
    }
  
    this.http.post("https://wisdomhighschool.in:8080/appliedcandidates",reqBody).subscribe(data=>{
      $(".close").click();
    });
  }
  changeFile(e){
    let reader=new FileReader();
let file=e.target.files[0];
reader.readAsDataURL(file);
reader.onload = () => {
  this.docFile={
    filename: file.name,
    filetype: file.type,
    value: this.getFileValue(reader.result)
  };
};
  }
  getFileValue(res){
    return res.split(",")[1];
  }
  resetForm(jobid){
    this.submitted=false;
    this.jobid=jobid;
    this.firstname='';
this.lastname='';
this.email='';
this.mobile='';
this.country='';
this.state='';
this.zip='';
this.city='';
this.docFile={};
  }
  ngOnInit() {
    
	  this.http.get('assets/countries.json').subscribe(data => {
      this.processData(data);
    });
  this.applyForm = this.formBuilder.group({
    firstname:['',Validators.required],
    lastname:['',Validators.required],
    email:['',[Validators.required,Validators.email]],
    mobile:['',Validators.required],
    country:['',Validators.required],
    state:['',Validators.required],
    zip:['',Validators.required],
    city:['',Validators.required]
  });

    this.usjobs=[];
    this.http.get("https://wisdomhighschool.in:8080/usjobs").subscribe((response)=>{
    this.usjobs=response;
    })
  }

}
