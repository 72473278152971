import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ContactusComponent } from './contactus/contactus.component';
import { WhyChooseUsComponent } from './why-choose-us/why-choose-us.component';
import { OurClientsComponent } from './our-clients/our-clients.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { EmployeeLoginComponent } from './employee-login/employee-login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BiSupportComponent } from './bi-support/bi-support.component';
import { MicrosoftPowerBiComponent } from './microsoft-power-bi/microsoft-power-bi.component';
import { OracleBiComponent } from './oracle-bi/oracle-bi.component';
import { ReportingAnalyticsComponent } from './reporting-analytics/reporting-analytics.component';
import { AutomationTestingComponent } from './automation-testing/automation-testing.component';
import { ErpTestingComponent } from './erp-testing/erp-testing.component';
import { SalesforceTestingComponent } from './salesforce-testing/salesforce-testing.component';
import { MobileTestingComponent } from './mobile-testing/mobile-testing.component';
import { EtlTestingComponent } from './etl-testing/etl-testing.component';
import { TestAdvisoryServicesComponent } from './test-advisory-services/test-advisory-services.component';
import { DatabaseTestingComponent } from './database-testing/database-testing.component';
import { WebApiTestingComponent } from './web-api-testing/web-api-testing.component';
import { MobileApplicationDevelopmentComponent } from './mobile-application-development/mobile-application-development.component';
import { ApiIntegrationComponent } from './api-integration/api-integration.component';
import { AnimationComponent } from './animation/animation.component';
import { ECommerceAppDevelopmentComponent } from './e-commerce-app-development/e-commerce-app-development.component';
import { ApplicationDevelopmentComponent } from './application-development/application-development.component';
import { ApplicationSupportComponent } from './application-support/application-support.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { BusinessConsultingComponent } from './business-consulting/business-consulting.component';
import { MarketingDevelopmentComponent } from './marketing-development/marketing-development.component';
import { CustomApplicationComponent } from './custom-application/custom-application.component';
import { BamsaComponent } from './bamsa/bamsa.component';
import { AmsComponent } from './ams/ams.component';
import { HmsComponent } from './hms/hms.component';
import { BfsComponent } from './bfs/bfs.component';
import { RemsComponent } from './rems/rems.component';
import { WholesalerRetailerComponent } from './wholesaler-retailer/wholesaler-retailer.component';
import { DomesticComponent } from './domestic/domestic.component';
import { GlobalComponent } from './global/global.component';           
import { AiMachineLearningComponent } from './ai-machine-learning/ai-machine-learning.component';
import { PredictiveAnalyticsComponent } from './predictive-analytics/predictive-analytics.component';
import { ReinforcementLearningComponent } from './reinforcement-learning/reinforcement-learning.component';
import { DeepLearningComponent } from './deep-learning/deep-learning.component';
import { BlockChainComponent } from './block-chain/block-chain.component';
import { RoboticProcessAutomationComponent } from './robotic-process-automation/robotic-process-automation.component';
import { InternetOfThingsComponent } from './internet-of-things/internet-of-things.component';
import { ScalaComponent } from './scala/scala.component';
import { SparkComponent } from './spark/spark.component';
import { KafkaComponent } from './kafka/kafka.component';
import { OozieComponent } from './oozie/oozie.component';
import { MapreduceComponent } from './mapreduce/mapreduce.component';
import { SalesforceComponent } from './salesforce/salesforce.component';
import { AwsComponent } from './aws/aws.component';
import { AzureComponent } from './azure/azure.component';
import { PythonComponent } from './python/python.component';
import { ServicenowComponent } from './servicenow/servicenow.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { StaffAugmentationSolutionsComponent } from './staff-augmentation-solutions/staff-augmentation-solutions.component';
import { FrequentlyAskedQuestionsComponent } from './frequently-asked-questions/frequently-asked-questions.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SocialLinksComponent } from './social-links/social-links.component';
import { enableProdMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import {HTTPStatus,HTTPListener} from './httplistener.service';
import { ApplyJobComponent } from './apply-job/apply-job.component';
import { FourzerofourComponent } from './fourzerofour/fourzerofour.component';


enableProdMode();


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactusComponent,
    WhyChooseUsComponent,
    OurClientsComponent,
    AdminLoginComponent,
    EmployeeLoginComponent,
    DashboardComponent,
    BiSupportComponent,
    MicrosoftPowerBiComponent,
    OracleBiComponent,
    ReportingAnalyticsComponent,
    AutomationTestingComponent,
    ErpTestingComponent,
    SalesforceTestingComponent,
    MobileTestingComponent,
    EtlTestingComponent,
    TestAdvisoryServicesComponent,
    DatabaseTestingComponent,
    WebApiTestingComponent,
    MobileApplicationDevelopmentComponent,
    ApiIntegrationComponent,
    AnimationComponent,
    ECommerceAppDevelopmentComponent,
    ApplicationDevelopmentComponent,
    ApplicationSupportComponent,
    DigitalMarketingComponent,
    BusinessConsultingComponent,
    MarketingDevelopmentComponent,
    CustomApplicationComponent,
    BamsaComponent,
    AmsComponent,
    HmsComponent,
    BfsComponent,
    RemsComponent,
    WholesalerRetailerComponent,
    DomesticComponent,
    GlobalComponent,
    AiMachineLearningComponent,
    PredictiveAnalyticsComponent,
    ReinforcementLearningComponent,
    DeepLearningComponent,
    BlockChainComponent,
    RoboticProcessAutomationComponent,
    InternetOfThingsComponent,
    ScalaComponent,
    SparkComponent,
    KafkaComponent,
    OozieComponent,
    MapreduceComponent,
    SalesforceComponent,
    AwsComponent,
    AzureComponent,
    PythonComponent,
    ServicenowComponent,
    NavbarComponent,
    FooterComponent,
    PortfolioComponent,
    StaffAugmentationSolutionsComponent,
    FrequentlyAskedQuestionsComponent,
    SitemapComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    SocialLinksComponent,
    ApplyJobComponent,
    FourzerofourComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [HTTPStatus,{
    provide: HTTP_INTERCEPTORS,
    useClass: HTTPListener,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
