import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http'; 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  messageForm: FormGroup;
  submitted = false;
  name="";
  email="";
  mobile="";
  message="";
  showSuccess=false;
  showError=false;
  constructor(private formBuilder:FormBuilder,private http:HttpClient) { }
  onSubmit(){
    this.submitted=true;
    if (this.messageForm.invalid) {
      return;
    }
    let data={
      name:this.name,
      email:this.email,
      mobile:this.mobile,
      message:this.message
    }
    this.http.post("https://wisdomhighschool.in:8080/contact",data).subscribe(response=>{
      //this.showSuccess=true;
      alert("Data submitted successfully");
      this.name="";
      this.email="";
      this.mobile="";
      this.message="";
      this.submitted=false;
      this.showMessage("success");
    },error=>{
      //this.showError=true;
      alert("Something went wrong.please try again");
      this.showMessage("error");
    });
  }
  showMessage(status){
    if(status=="success"){      
      setTimeout(function(){
        this.showSuccess=false;
      },5000);
    }else{
      setTimeout(function(){
        this.showError=false;
      },5000);
    }
  }
  ngOnInit() {
    this.messageForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required,Validators.email]],
      mobile: ['', [Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
      message: ['', Validators.required]
    });
  }


}
