import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporting-analytics',
  templateUrl: './reporting-analytics.component.html',
  styleUrls: ['./reporting-analytics.component.scss']
})
export class ReportingAnalyticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
