import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-advisory-services',
  templateUrl: './test-advisory-services.component.html',
  styleUrls: ['./test-advisory-services.component.scss']
})
export class TestAdvisoryServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
