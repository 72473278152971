import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-consulting',
  templateUrl: './business-consulting.component.html',
  styleUrls: ['./business-consulting.component.scss']
})
export class BusinessConsultingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
