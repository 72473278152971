import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reinforcement-learning',
  templateUrl: './reinforcement-learning.component.html',
  styleUrls: ['./reinforcement-learning.component.scss']
})
export class ReinforcementLearningComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
