import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactusComponent } from './contactus/contactus.component';
import { WhyChooseUsComponent } from './why-choose-us/why-choose-us.component';
import { OurClientsComponent } from './our-clients/our-clients.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { EmployeeLoginComponent } from './employee-login/employee-login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BiSupportComponent } from './bi-support/bi-support.component';
import { MicrosoftPowerBiComponent } from './microsoft-power-bi/microsoft-power-bi.component';
import { OracleBiComponent } from './oracle-bi/oracle-bi.component';
import { ReportingAnalyticsComponent } from './reporting-analytics/reporting-analytics.component';
import { AutomationTestingComponent } from './automation-testing/automation-testing.component';
import { ErpTestingComponent } from './erp-testing/erp-testing.component';
import { SalesforceTestingComponent } from './salesforce-testing/salesforce-testing.component';
import { MobileTestingComponent } from './mobile-testing/mobile-testing.component';
import { EtlTestingComponent } from './etl-testing/etl-testing.component';
import { TestAdvisoryServicesComponent } from './test-advisory-services/test-advisory-services.component';
import { DatabaseTestingComponent } from './database-testing/database-testing.component';
import { WebApiTestingComponent } from './web-api-testing/web-api-testing.component';
import { MobileApplicationDevelopmentComponent } from './mobile-application-development/mobile-application-development.component';
import { ApiIntegrationComponent } from './api-integration/api-integration.component';
import { AnimationComponent } from './animation/animation.component';
import { ECommerceAppDevelopmentComponent } from './e-commerce-app-development/e-commerce-app-development.component';
import { ApplicationDevelopmentComponent } from './application-development/application-development.component';
import { ApplicationSupportComponent } from './application-support/application-support.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { BusinessConsultingComponent } from './business-consulting/business-consulting.component';
import { MarketingDevelopmentComponent } from './marketing-development/marketing-development.component';
import { CustomApplicationComponent } from './custom-application/custom-application.component';
import { BamsaComponent } from './bamsa/bamsa.component';
import { AmsComponent } from './ams/ams.component';
import { HmsComponent } from './hms/hms.component';
import { BfsComponent } from './bfs/bfs.component';
import { RemsComponent } from './rems/rems.component';
import { WholesalerRetailerComponent } from './wholesaler-retailer/wholesaler-retailer.component';
import { DomesticComponent } from './domestic/domestic.component';
import { GlobalComponent } from './global/global.component';
import { AiMachineLearningComponent } from './ai-machine-learning/ai-machine-learning.component';
import { PredictiveAnalyticsComponent } from './predictive-analytics/predictive-analytics.component';
import { ReinforcementLearningComponent } from './reinforcement-learning/reinforcement-learning.component';
import { DeepLearningComponent } from './deep-learning/deep-learning.component';
import { BlockChainComponent } from './block-chain/block-chain.component';
import { RoboticProcessAutomationComponent } from './robotic-process-automation/robotic-process-automation.component';
import { InternetOfThingsComponent } from './internet-of-things/internet-of-things.component';
import { ScalaComponent } from './scala/scala.component';
import { SparkComponent } from './spark/spark.component';
import { KafkaComponent } from './kafka/kafka.component';
import { OozieComponent } from './oozie/oozie.component';
import { MapreduceComponent } from './mapreduce/mapreduce.component';
import { SalesforceComponent } from './salesforce/salesforce.component';
import { AwsComponent } from './aws/aws.component';
import { AzureComponent } from './azure/azure.component';
import { PythonComponent } from './python/python.component';
import { ServicenowComponent } from './servicenow/servicenow.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { StaffAugmentationSolutionsComponent } from './staff-augmentation-solutions/staff-augmentation-solutions.component';
import { FrequentlyAskedQuestionsComponent } from './frequently-asked-questions/frequently-asked-questions.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SocialLinksComponent } from './social-links/social-links.component';
import { ApplyJobComponent } from './apply-job/apply-job.component';
const routes: Routes = [
  {path: '', component: HomeComponent },
  {path: 'contact', component: ContactusComponent },
  {path: 'about-us/why-choose-us', component: WhyChooseUsComponent },
  {path: 'about-us/our-clients', component: OurClientsComponent },
  {path: 'aboutus/admin-login', component: AdminLoginComponent },
  {path: 'aboutus/employee-login', component: EmployeeLoginComponent },
  {path: 'services/business-intelligence-analytics/dashboard-services', component: DashboardComponent },
  {path: 'services/business-intelligence-analytics/bi-support', component: BiSupportComponent },
  {path: 'services/business-intelligence-analytics/microsoft-power-bi', component: MicrosoftPowerBiComponent },
  {path: 'services/business-intelligence-analytics/oracle-bi', component: OracleBiComponent },
  {path: 'services/business-intelligence-analytics/reporting-analytics', component:  ReportingAnalyticsComponent },
  {path: 'services/quality-assurance-testing/automation-testing', component:  AutomationTestingComponent },
  {path: 'services/quality-assurance-testing/erp-testing', component:  ErpTestingComponent },
  {path: 'services/quality-assurance-testing/salesforce-testing', component:  SalesforceTestingComponent },
  {path: 'services/quality-assurance-testing/mobile-testing', component:  MobileTestingComponent },
  {path: 'services/quality-assurance-testing/etl-testing', component:  EtlTestingComponent },
  {path: 'services/quality-assurance-testing/test-advisory-services', component:  TestAdvisoryServicesComponent },
  {path: 'services/quality-assurance-testing/database-testing', component:  DatabaseTestingComponent },
  {path: 'services/quality-assurance-testing/web-api-testing', component:  WebApiTestingComponent },
  {path: 'services/mobile-development/mobile-application-development', component:  MobileApplicationDevelopmentComponent },
  {path: 'services/api-integration/api-integration', component:  ApiIntegrationComponent },
  {path: 'services/animation/3d-animation', component: AnimationComponent },
  {path: 'services/other-services/e-commerce-app-development', component: ECommerceAppDevelopmentComponent },
  {path: 'services/other-services/application-development', component: ApplicationDevelopmentComponent },
  {path: 'services/other-services/application-support', component: ApplicationSupportComponent },
  {path: 'services/other-services/digital-marketing', component: DigitalMarketingComponent },
  {path: 'services/other-services/business-consulting', component: BusinessConsultingComponent },
  {path: 'services/other-services/marketing-development', component: MarketingDevelopmentComponent },
  {path: 'services/other-services/custom-application', component: CustomApplicationComponent },
  {path: 'product/bamsa', component: BamsaComponent },
  {path: 'product/ams', component: AmsComponent },
  {path: 'product/hms', component: HmsComponent },
  {path: 'product/bfs', component: BfsComponent },
  {path: 'product/rems', component: RemsComponent },
  {path: 'product/wholesaler-retailer', component: WholesalerRetailerComponent },
  {path: 'careers/domestic', component: DomesticComponent },
  {path: 'careers/global', component: GlobalComponent },
  {path: 'solutions/data-science/ai-machine-learning-in-Hyderabad-India', component: AiMachineLearningComponent },
  {path: 'solutions/data-science/predictive-analytics-in-Hyderabad-India', component: PredictiveAnalyticsComponent },
  {path: 'solutions/data-science/reinforcement-learning-in-Hyderabad-India', component: ReinforcementLearningComponent },
  {path: 'solutions/data-science/deep-learning-in-Hyderabad-India', component: DeepLearningComponent },
  {path: 'solutions/data-science/block-chain-in-Hyderabad-India', component: BlockChainComponent },
  {path: 'solutions/data-science/robotic-process-automation-in-Hyderabad-India', component: RoboticProcessAutomationComponent },
  {path: 'solutions/data-science/internet-of-things-in-Hyderabad-India', component: InternetOfThingsComponent },
  {path: 'solutions/big-data/scala-in-Hyderabad-India', component: ScalaComponent },
  {path: 'solutions/big-data/spark-in-Hyderabad-India', component: SparkComponent },
  {path: 'solutions/big-data/kafka-in-Hyderabad-India', component: KafkaComponent },
  {path: 'solutions/big-data/oozie-in-Hyderabad-India', component: OozieComponent },
  {path: 'solutions/big-data/mapreduce-in-Hyderabad-India', component: MapreduceComponent },
  {path: 'solutions/cloud-technologies/salesforce-in-Hyderabad-India', component: SalesforceComponent },
  {path: 'solutions/cloud-technologies/aws-in-Hyderabad-India', component: AwsComponent },
  {path: 'solutions/cloud-technologies/azure-in-Hyderabad-India', component: AzureComponent},
  {path: 'solutions/python/python-in-Hyderabad-India', component: PythonComponent },
  {path: 'solutions/servicenow/servicenow-in-Hyderabad-India', component: ServicenowComponent },
  {path: 'navbar', component: NavbarComponent },
  {path: 'portfolio', component: PortfolioComponent },
  {path: 'staff-augmentation-solutions', component: StaffAugmentationSolutionsComponent },
  {path: 'frequently-asked-questions', component: FrequentlyAskedQuestionsComponent },
  {path: 'application-sitemap', component: SitemapComponent},
  {path: 'terms-conditions', component: TermsConditionsComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'social-links', component: SocialLinksComponent},
  {path: 'apply-job', component: ApplyJobComponent},
  
];                                   

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
