import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oracle-bi',
  templateUrl: './oracle-bi.component.html',
  styleUrls: ['./oracle-bi.component.scss']
})
export class OracleBiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
