import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ams',
  templateUrl: './ams.component.html',
  styleUrls: ['./ams.component.scss']
})
export class AmsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
